// ****************************************************************************
// *                                 mixin                                    *
// ****************************************************************************

@mixin sp-layout() {
  @media (max-width: $mobile - 1) {
    @content;
  }
}

@mixin tb-layout() {
  @media (min-width: $mobile) and (max-width: $pc) {
    @content;
  }
}

@mixin pc-layout() {
  @media (min-width: $mobile) {
    @content;
  }
}

@mixin lg-layout() {
  @media (min-width: $lg) {
    @content;
  }
}

@mixin mq($width, $type: min) {
  @if $type==max {
    $width: $width - 1px;
  } @else {
    $width: $width + px;
  }

  @media (#{$type}-width: $width) {
    @content;
  }
}

@mixin mq-mix($width1, $width2) {
  $width1: $width1 + px;
  $width2: $width2 + px;

  @media (min-width: $width1) and (max-width: $width2) {
    @content;
  }
}

@mixin font($name) {
  @font-face {
    font-family: $name;
    src: url('../fonts/' + $name + '.eot?#iefix') format('embedded-opentype'),
      url('../fonts/' + $name + '.woff') format('woff'),
      url('../fonts/' + $name + '.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}
